import * as Sentry from '@sentry/browser';
import { AxiosError } from 'axios';
import { BUILDKITE_COMMIT, ENVIRONMENT } from './Environment';

const is400Error = (hint) => {
  const { originalException } = hint;
  if (!(originalException instanceof AxiosError)) return false;
  const {
    response: {
      status
    } = {}
  } = originalException;
  return status === 400;
};

const isNetworkError = (hint) => {
  const { originalException } = hint;

  return (
    (originalException instanceof AxiosError && originalException.code == AxiosError.ERR_NETWORK)
    || (originalException instanceof Error && originalException.message == 'Network Error')
    || (originalException instanceof AxiosError && originalException.code == AxiosError.ECONNABORTED)
    || (originalException instanceof Error && originalException.message == 'Request aborted')
  );
};

const getFullUTCTimestamp = (date) => {
  const pad = (number, zeros = 2) => (`${new Array(zeros).fill(0)}${number}`).slice(-zeros);
  const dates = [
    pad(date.getUTCFullYear(), 4),
    pad(date.getUTCMonth() + 1),
    pad(date.getUTCDate()),
  ].join('-');
  const time = [
    pad(date.getUTCHours()),
    pad(date.getUTCMinutes()),
    pad(date.getUTCSeconds()),
  ].join(':');
  return `${dates}T${time}.${pad(date.getMilliseconds(), 3)}Z`;
};

Sentry.init({
  dsn: 'https://af86dc6902c2499db342cdf7ee677da5@o304579.ingest.sentry.io/6144643',
  release: BUILDKITE_COMMIT || '0.0.1-dev',
  environment: window.APP_ENV,
  tracesSampleRate: 0.2,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'xhr') {
      const { xhr } = hint;
      const now = new Date();
      breadcrumb.data = {
        ...breadcrumb.data,
        start: getFullUTCTimestamp(new Date(xhr.loadstartTime)),
        end: getFullUTCTimestamp(now),
        millisecondsWaitingForResponse: now.valueOf() - xhr.loadstartTime
      };
    }

    if (breadcrumb.category === 'console') {
      return null;
    }

    return breadcrumb;
  },
  beforeSend: (event, hint) => {
    if (window.isValidBrowser == false) return null; //Skips sending Sentry event for unsupported browsers
    const errorText = event.exception?.values?.[0]?.value || '';
    if (errorText == 'ResizeObserver loop limit exceeded') return null;

    if (is400Error(hint)) return; //Skips sending Sentry event for 400 errors

    if (isNetworkError(hint)) return; //Skips sending Sentry event for network errors

    const isDev = ['development', 'test'].includes(ENVIRONMENT);
    if (isDev) {
      if (event.exception) {
        console.error(hint.originalException); // eslint-disable-line no-console
        console.log('Sentry', event, hint); // eslint-disable-line no-console
      }
      return;
    }
    return event;
  },
  integrations: [
    Sentry.extraErrorDataIntegration(),
    Sentry.browserTracingIntegration()
  ],
  ignoreErrors: [
    'Non-Error promise rejection captured'
  ]
});

window.Promise._unhandledRejectionFn = error => Sentry.captureException(error);
