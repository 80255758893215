export const FONT_FAMILY = '"Montserrat", sans-serif';

export const DEFAULT_AYT_ORANGE = '#F16E00';

export const CONTRAST_GRAY = '#1D1A1A';
export const SYSTEM_COLOR = {
  PRIMARY: DEFAULT_AYT_ORANGE,
  TEXT_BLACK: '#323232',
  GRAY: {
    _100: '#FAFAFA',
    _200: '#F4F4F4',
    _300: '#EAEAEA',
    _400: '#D3D3D3',
    _500: '#BCBCBC',
    _600: '#959595',
    _700: '#747474',
    _800: '#676767',
    _900: '#585858',
    _1000: '#505050',
    _1100: '#323232',
  },
};

export const PRIMARY_COLOR = {
  _50: '#F8F3CD',
  _100: '#FDF3B3',
  _200: '#FFE37A',
  _300: '#FFC946',
  _400: '#F59340',
  _500: DEFAULT_AYT_ORANGE,
  _600: '#E45A00',
  _700: '#D65400',
  softBg: '#FFD9C7'
};

export const SUCCESS_COLOR = {
  _500: '#0E8A00',
  _600: '#106D07',
  _700: '#115C0B',
};

export const DANGER_COLOUR = {
  _500: '#E03100',
  _600: '#C82C00',
  _700: '#AC2600',
};

export const CONTAINER_WIDTH = '960px';

export const SURVEY_STATE_VALUES = {
  DRAFT: 'draft',
  PENDING: 'pending',
  ACTIVE: 'active',
  CLOSED: 'closed'
};

export const RESPONDENT_SURVEY_STATUS_VALUES = {
  NOT_STARTED: 'Not Started',
  ENTERING_DEMOGRAPHICS: 'Entering Demographics', // Client only
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed'
};

export const KIOSK_TIMEOUT_VALID_STATES = [
  RESPONDENT_SURVEY_STATUS_VALUES.IN_PROGRESS,
  RESPONDENT_SURVEY_STATUS_VALUES.ENTERING_DEMOGRAPHICS,
  RESPONDENT_SURVEY_STATUS_VALUES.COMPLETED
];

export const FEEDBACK_SUBGROUP_OPTIONS = {
  SERVICE_LEADER: 'Service leader',
  SERVICE_STAFF: 'Service staff',
  SUPPORT_STAFF: 'Support staff',
  EXECUTIVE: 'Executive'
};

export const DATE_NUMBERS = {
  SECOND: 1000,
  MINUTE: 1000 * 60,
  HOUR: 1000 * 60 * 60,
  DAY: 1000 * 60 * 60 * 24
};

export const LOCALE_CODES = {
  'en': 'English',
  'zh-CN': '中文',
  'th': 'ภาษาไทย',
  'mi-NZ': 'Te Reo Māori',
  'fr': 'Français',
  'es': 'Español',
  'es-CL': 'Español - Chile',
  'es-MX': 'Español - Mexico',
  'ar': 'عربي',
  'bi': 'Bislama',
  'pis': 'Solomon Pijin',
  'to': 'Lea faka-Tonga',
  'sm': 'Gagana Sāmoa',
  'pt-BR': 'Português - BR',
  'hi': 'हिन्दी',
  'pa': 'ਪੰਜਾਬੀ',
  'ro': 'Română',
  'vi-VN': 'Tiếng Việt',
  'fil': 'Filipino',
  'id': 'Bahasa Indonesia',
  'bn': 'বাংলা'
};

export const RECAPTCHA_SITE_PUBLIC_KEY = {
  V2: () => {
    switch (APP_ENV) {
      case 'production': return '6LckdUkeAAAAAFNT-b-UYyPg-OFAWQJn_p-XDwfK';
      case 'training': return '6Le-dEkeAAAAALlgbGX9PyPPawXY8DmEgVpNxJzL';
      case 'staging': return '6Le2dEkeAAAAAFoE5Zz18PIgy0YSA2v2Trj1DCBF';
      default: return '6Lfve0keAAAAACt1yRL6ZLkXjTlgWXZHEiOYrE43';
    }
  },
  V3: () => {
    switch (APP_ENV) {
      case 'production': return '6Lc8XX4UAAAAACsBgBvjYIMXWZSWKofMmQkrgtJ1';
      case 'training': return '6Lf6YH4UAAAAAKYNtYYhqYBnfqwEkTX1vsTj2BqC';
      case 'staging': return '6LeIZH4UAAAAACjbGRywmVVkp3imV6LbQbJaMRRG';
      default: return '6Leg0SIbAAAAACl39HGmJqL-ktR3im7wwfS01rR-';
    }
  }
};

// local session token only used if localStorage is not available
// assigned in setSessionToken method
export const BROWSER_TOKEN = { value: undefined };
