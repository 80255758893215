import axios from 'axios';
import { undefinedToNull, nullToUndefined, isObject, hasLocalStorageAvailable } from 'lib/Utils';
import { BROWSER_TOKEN } from 'lib/Enum';
import { convertServerErrorsToMap } from './Utils';

const GET_SESSION_TOKEN = () => {

  if (hasLocalStorageAvailable) {
    const url = window.location.pathname;
    const feedbackID = url ? url?.split('/')[1] : '';
    return localStorage.getItem(`session_token_${feedbackID}`);
  }

  return BROWSER_TOKEN.value;
};

const OAUTH_HELPERS = {
  API_DOMAIN: () => {
    switch (APP_ENV) {
      case ('production'): return 'https://app.askyourteam.com';
      case ('training'): return 'https://app.training-askyourteam.net';
      case ('staging'): return 'https://app.staging-askyourteam.net';
      default: return 'http://localhost:3005';
    }
  },
  onInterceptFailed: () => { },
  getAuthorization: () => {
    const token = GET_SESSION_TOKEN();
    return token ? `Bearer ${token}` : null;
  }
};

export class OAuth {
  constructor() {
    this.resetDomain();
    this.source = axios.CancelToken.source();
    this.running = false;
  }

  static get API_DOMAIN() {
    return OAUTH_HELPERS.API_DOMAIN();
  }

  resetDomain = () => {
    this.domain = OAuth.API_DOMAIN;
  }

  abort = () => {
    this.cancelCallback();
    this.source.cancel(new Error('Cancelled request'));
    this.source = axios.CancelToken.source();
  }

  ifShouldThenAbort = () => {
    if (this.running) this.abort();
    this.running = true;
  }

  callAPI = (method, url, config) => {
    this.ifShouldThenAbort();
    return this._axios(
      url,
      {
        method,
        ...config
      }
    );
  }

  getAPI = (url, params, config) => this.callAPI('GET', url, { params, ...config })
  postAPI = (url, data, config) => this.callAPI('POST', url, { data, ...config })
  putAPI = (url, data, config) => this.callAPI('PUT', url, { data, ...config })

  beforeCallback = () => { }
  afterCallback = () => { }
  cancelCallback = () => { }
  onInterceptFailed = () => { }
  interceptSuccess = async response => {
    const {
      status,
      statusText,
      data
    } = response;

    this.running = false;

    const successStatus = [200, 201, 202, 204].includes(status);
    if (successStatus) return {
      _status_code: status,
      ...isObject(data)
        ? nullToUndefined(data)
        : ({ data })
    };

    const error = new Error(statusText);
    error.response = response;
    OAUTH_HELPERS.onInterceptFailed(error);
    return Promise.reject(error);
  }

  interceptFailure = error => {
    this.running = false;
    if (axios.isCancel(error)) error = new Error('Cancelled request');
    if (!error.message) error.message = 'Network Error';
    if (!error.response) error.response = { data: {} };
    if (error?.response?.data?.errors) error.errors = convertServerErrorsToMap(error.response.data.errors);

    OAUTH_HELPERS.onInterceptFailed(error);
    return Promise.reject(error);
  }

  _axios = (url, options) => {
    const instance = axios.create({
      timeout: 15000,
    });
    if (options.data) options.data = JSON.stringify(undefinedToNull(options.data));
    this.beforeCallback();

    instance.interceptors.response.use(
      response => {
        this.afterCallback(response.status);
        return this.interceptSuccess(response);
      },
      error => {
        this.afterCallback(error?.response?.status);
        return this.interceptFailure(error);
      }
    );
    const Authorization = OAUTH_HELPERS.getAuthorization();
    return instance({
      ...options,
      url,
      baseURL: this.domain,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...Authorization && ({ Authorization }),
        ...options.headers
      },
      cancelToken: this.source.token
    });
  }
}

export default OAuth;
